import { ReactNode, useRef, useState } from 'react';

import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    NavigationBar,
    TooltipHover,
    Text,
    VSpacing,
} from '@hh.ru/magritte-ui';

import { useIsTextClamped } from 'src/hooks/useIsTextClamped';

import Item, { ItemProps } from 'src/components/Employer/EmployerView/About/Item';

const TrlKeys = {
    good: 'employerView.rightPanel.clampedItem.botomSheet.goodText',
};

const ClampedItem: TranslatedComponent<ItemProps & { tooltipTitle: ReactNode; bottomSheetContent: string }> = ({
    tooltipTitle,
    bottomSheetContent,
    onClick,
    trls,
    ...props
}) => {
    const ref = useRef(null);
    const isClamped = useIsTextClamped(ref);
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
        onClick?.();
        setVisible(true);
    };

    return (
        <>
            <Item {...props} onClick={handleClick} ref={ref} />
            {isClamped && (
                <>
                    <TooltipHover placement="top-center" activatorRef={ref} size="small">
                        {tooltipTitle}
                    </TooltipHover>
                    <BottomSheet
                        showDivider="always"
                        header={<NavigationBar showDivider="always" title={props.subtitle} />}
                        footer={
                            <BottomSheetFooter>
                                <Button mode="secondary" style="accent" onClick={() => setVisible(false)}>
                                    {trls[TrlKeys.good]}
                                </Button>
                            </BottomSheetFooter>
                        }
                        visible={visible}
                        onClose={() => setVisible(false)}
                    >
                        <Text>{bottomSheetContent}</Text>
                        <VSpacing default={16} />
                    </BottomSheet>
                </>
            )}
        </>
    );
};

export default translation(ClampedItem);
