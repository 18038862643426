import { FC } from 'react';

import { VSpacing, HSpacingContainer, VSpacingContainer } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import EmployerPageTabs from 'src/components/Employer/PageTabs/EmployerPageTabs';
import { usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';
import WantWorkHere from 'src/components/Employer/Sidebar/WantWorkHere';
import EmployerHHRatingIcon from 'src/components/EmployerHHRatingIcon';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import HrBrandIcon from 'src/components/HrBrandIcon';
import { useGetCompanyHHRatingBadge } from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useSelector } from 'src/hooks/useSelector';

import OpenEmployerIcon from 'src/components/Employer/ChameleonHeader/components/OpenEmployerIcon';
import WantWorkMobile from 'src/components/Employer/ChameleonHeader/components/WantWorkMobile';

import styles from './styles.less';

const ChameleonHeader: FC = () => {
    const hrBrand = useSelector((state) => state.employerInfo.hrBrand);
    const badges = useSelector((state) => state.employerInfo.badges);
    const accepted = useSelector((state) => state.employerInfo.accepted);
    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);

    const { isMobileView } = usePageTabsContext();

    return (
        <ColumnsWrapper>
            <Column l="16" m="12" s="8" xs="4">
                {!isMobileView && (
                    <>
                        <WantWorkHere />
                        <VSpacing default={24} />
                    </>
                )}
                <div className={styles.employerChameleonHeader}>
                    <HSpacingContainer default={8}>
                        {<OpenEmployerIcon size="medium" />}
                        {employerHHRatingBadge && (
                            <EmployerHHRatingIcon badge={employerHHRatingBadge} size="medium" dataQaPrefix="sidebar-" />
                        )}
                        {hrBrand && <HrBrandIcon hrBrand={hrBrand} size="medium" dataQa="sidebar-hr-brand-icon" />}
                    </HSpacingContainer>
                    {accepted && <EmployerReviewsWidgetSmall />}
                </div>
                {isMobileView && (
                    <>
                        <VSpacingContainer default={8}>
                            <EmployerPageTabs />
                            <WantWorkMobile />
                        </VSpacingContainer>
                    </>
                )}
                <VSpacing default={28} />
            </Column>
        </ColumnsWrapper>
    );
};

export default ChameleonHeader;
