import { FC, PropsWithChildren } from 'react';

import { useTopImagePath } from 'src/components/Employer/EmployerView/Header/hooks/useTopImagePath';

import styles from './header-wrapper.less';

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
    const path = useTopImagePath();

    return <div className={path ? styles.withTopImage : undefined}>{children}</div>;
};

export default HeaderWrapper;
