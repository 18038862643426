import { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';

import { useTopImagePath } from 'src/components/Employer/EmployerView/Header/hooks/useTopImagePath';

import styles from './top-image.less';

const TopImage: FC = () => {
    const hhcdnHost = useSelector((state) => state.config.hhcdnHost);
    const path = useTopImagePath();

    if (!path) {
        return null;
    }

    return <div className={styles.root} style={{ backgroundImage: `url(${hhcdnHost}${path})` }} />;
};

export default TopImage;
