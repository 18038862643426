import { FC, PropsWithChildren } from 'react';

import EmployerView from 'src/components/Employer/EmployerView';
import PageTabsProvider from 'src/components/Employer/PageTabs/PageTabsProvider';
import SubscribeProvider from 'src/components/Employer/Subscribe/SubscribeProvider';
import WantSame from 'src/components/VacancyView/WantSame';
import { useSelector } from 'src/hooks/useSelector';
// На этой странице нужны динамические импорты с целью уменьшения чанка
// eslint-disable-next-line @hh.ru/rules/no-internal-modules
import EmployerConstructor from 'src/pages/EmployerConstructor/components/EmployerConstructorContent';
// eslint-disable-next-line @hh.ru/rules/no-internal-modules
import EmployerSimple from 'src/pages/EmployerSimpleEdit/components/EmployerSimpleEditContent';

import EmployerChameleon from 'src/pages/EmployerView/components/EmployerChameleon';
import { useSendUxFeedbackOnEmployerView } from 'src/pages/EmployerView/hooks/useSendUxFeedbackOnEmployerView';

const ProvidersWrapper: FC<PropsWithChildren> = ({ children }) => (
    <SubscribeProvider>
        <PageTabsProvider>{children}</PageTabsProvider>
    </SubscribeProvider>
);

const EmployerViewRoute: FC = () => {
    const hasConstructor = useSelector((state) => state.employerConstructor.hasConstructor);
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const employerId = useSelector((state) => state.employerInfo?.id);
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);

    useSendUxFeedbackOnEmployerView();

    if (hasChameleon) {
        return (
            <ProvidersWrapper>
                <EmployerChameleon />
            </ProvidersWrapper>
        );
    }

    const renderSimpleView = () => (isMagritteEmployerPageHeaderExp ? <EmployerView /> : <EmployerSimple />);

    return (
        <ProvidersWrapper>
            {hasConstructor ? (
                <>
                    {isMagritteEmployerPageHeaderExp ? <EmployerView /> : <EmployerConstructor />}
                    <WantSame keyPrefix="employerId" id={String(employerId)} />
                </>
            ) : (
                renderSimpleView()
            )}
        </ProvidersWrapper>
    );
};

export default EmployerViewRoute;
