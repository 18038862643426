import { memo, useRef } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerAchievementsSmallWidgetButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievements_small_widget_button_click';
import employerAchievementsSmallWidgetElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievements_small_widget_element_shown';
import { employerHeaderTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_header_tooltip_element_shown';
import { TooltipHover, Text } from '@hh.ru/magritte-ui';
import {
    DreamjobSize16,
    ItAccreditationSize16,
    HrBrandOutlinedSize16,
    EmployersRatingOutlinedSize16,
    CompanysLifeOutlinedSize16,
} from '@hh.ru/magritte-ui/product';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useAchievementsList } from 'src/components/Employer/EmployerView/hooks/useAchievementsList';
import { EmployerPageTab, usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';
import { useEmployerReviewsSmallWidgetCanBeRendered } from 'src/components/EmployerReviews/WidgetSmall/useEmployerReviewsSmallWidgetHasContent';
import translation from 'src/components/translation';
import { useGetCompanyHHRatingBadge } from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useSelector } from 'src/hooks/useSelector';

import Badge from 'src/components/Employer/EmployerView/Header/MiniAdvantages/Badge';
import Separator from 'src/components/Employer/EmployerView/Header/MiniAdvantages/Separator';

import styles from './mini-advantages.less';

const TrlKeys = {
    goToAdvantages: 'employerView.header.goToAdvantages',
};

// it-accreditation
// dreamjob
// hr-brand
// employers-rating
// интервью
// TODO: спецпроекты при раскатке экспа

const MiniAdvantages: TranslatedComponent = ({ trls }) => {
    const accreditedITEmployer = useSelector((state) => state.employerInfo.accreditedITEmployer);
    const isOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const hrBrand = useSelector((state) => state.employerInfo.hrBrand);
    const badges = useSelector((state) => state.employerInfo.badges);
    const insider = useSelector((state) => state.employerInsiderInterview);
    const hasInsiderArticles = !!insider?.articles?.length;
    const employerId = useSelector((state) => state.employerInfo.id);

    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);
    const achievements = useAchievementsList();
    const shouldRenderSeparator = useEmployerReviewsSmallWidgetCanBeRendered();

    const { currentTab, setCurrentTab } = usePageTabsContext();

    const activatorRef = useRef(null);

    if (!(accreditedITEmployer || isOpenEmployer || hrBrand || employerHHRatingBadge || hasInsiderArticles)) {
        return null;
    }

    return (
        <>
            <TooltipHover
                placement="bottom-center"
                size="small"
                activatorRef={activatorRef}
                onAppear={() =>
                    employerHeaderTooltipElementShownRaw({
                        employerId: String(employerId),
                        tooltipType: 'to_achievements',
                    })
                }
            >
                <Text>{trls[TrlKeys.goToAdvantages]}</Text>
            </TooltipHover>
            {shouldRenderSeparator && <Separator />}
            <ElementShownAnchor
                fn={employerAchievementsSmallWidgetElementShown}
                employerId={String(employerId)}
                achievementsList={achievements}
            >
                <div
                    className={styles.root}
                    onClick={() => {
                        employerAchievementsSmallWidgetButtonClick({
                            employerId: String(employerId),
                            achievementsList: achievements,
                        });
                        const handler = () => {
                            const scrollTarget = document.querySelector<HTMLDivElement>(
                                '[data-scroll-target="employer-advantages"]'
                            );
                            if (!scrollTarget) {
                                return;
                            }

                            window.scrollTo({ top: scrollTarget.offsetTop - 100, behavior: 'smooth' });
                        };

                        if (currentTab !== EmployerPageTab.Description) {
                            setCurrentTab(EmployerPageTab.Description);
                            setTimeout(handler);
                        } else {
                            handler();
                        }
                    }}
                    ref={activatorRef}
                >
                    {accreditedITEmployer && (
                        <Badge>
                            <ItAccreditationSize16 />
                        </Badge>
                    )}
                    {isOpenEmployer && (
                        <Badge>
                            <DreamjobSize16 />
                        </Badge>
                    )}
                    {hrBrand && (
                        <Badge>
                            <HrBrandOutlinedSize16 />
                        </Badge>
                    )}
                    {employerHHRatingBadge && (
                        <Badge>
                            <EmployersRatingOutlinedSize16 />
                        </Badge>
                    )}
                    {hasInsiderArticles && (
                        <Badge>
                            <CompanysLifeOutlinedSize16 />
                        </Badge>
                    )}
                </div>
            </ElementShownAnchor>
        </>
    );
};

export default memo(translation(MiniAdvantages));
