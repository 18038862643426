import { useMemo } from 'react';

import { useGetCompanyHHRatingBadge } from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useSelector } from 'src/hooks/useSelector';

const useAchievementsList = () => {
    const accreditedITEmployer = useSelector((state) => state.employerInfo.accreditedITEmployer);
    const isOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const hrBrand = useSelector((state) => state.employerInfo.hrBrand);
    const badges = useSelector((state) => state.employerInfo.badges);
    const insider = useSelector((state) => state.employerInsiderInterview);
    const hasInsiderArticles = !!insider?.articles?.length;

    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);

    return useMemo(() => {
        const result = [];
        if (accreditedITEmployer) {
            result.push('it_company');
        }
        if (isOpenEmployer) {
            result.push('transparency');
        }
        if (hrBrand) {
            if (hrBrand.nominees) {
                result.push('hr_brand_nominee');
            }

            if (hrBrand.winners) {
                result.push('hr_brand_winners');
            }
        }
        if (employerHHRatingBadge) {
            result.push('rating_hh');
        }

        if (hasInsiderArticles) {
            result.push('interview');
        }
        // TODO: при раскатке добавить для game_about_company

        return result.join(',');
    }, [accreditedITEmployer, employerHHRatingBadge, hasInsiderArticles, hrBrand, isOpenEmployer]);
};

export { useAchievementsList };
