import { useSelector } from 'src/hooks/useSelector';

const useTopImagePath = () => {
    const imagePath = useSelector(
        (state) =>
            state.employerConstructor.images.find((image) => image.pictureId === state.employerConstructor.pictureId)
                ?.path
    );

    return imagePath;
};

export { useTopImagePath };
